import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Resource from "../components/Resource"
import Section from "../components/Section"
import SEO from "../components/Seo"
import SubFooterImage from "../images/theme/img-subfooter-students.jpg"

const StudentsPage = () => (
  <Layout>
    <SEO title="Students" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Hero
        title="Students"
        infographic="img-hero-infographic-students.png"
        body="Take charge of your choices! Turn the tables on peer pressure and substance misuse to empower ourselves for a healthier, smarter tomorrow!"
        image="img-hero-students.jpg"
      />
    </Section>

    <Section className="resource-container midgray-background">
      <Column span={12}>
        <Container>
          <div>
            <h2>Interactive Animation</h2>
            <p>
              Engage in an interactive animated adventure empowering healthy
              choices and decision-making skills.
            </p>
          </div>
        </Container>
        <Resource
          labelWithNewFlag="Interactive Animation"
          image="CVS-DoK-Student-Think-It-Through-Animation-Thumb-v2@3x.png"
          title="Think It Through: A choose-your-own-path experience"
          grade="Grades 9-12"
          description={
            <p>
              Facing the common consequences of poor time management, students
              will practice healthy decision-making skills to navigate a hectic
              day while faced with various social pressures and quick fixes
              leading up to the big test!
            </p>
          }
          actions={[
            {
              label: "Get Started",
              path: "/educators/interactive",
              type: "module",
              moduleName: "Think It Through: A choose-your-own-path experience",
              locked: false,
            },
          ]}
          horizontal
        />
      </Column>
    </Section>

    {/* Old manual resource layout with embedded player */}
    {/* <Section className="midgray-background">
      <Container>
        <Row>
          <Column span={8}>
            <h2>Interactive Animation</h2>
            <p>
              Engage in an interactive animated adventure empowering healthy
              choices and uncovering the impacts of decisions related to
              substance misuse.
            </p>
          </Column>
        </Row>
        <div className="resource__label">Interactive Animation</div>
        <Row className="white-background educators__CYA-row">
          <Column span={5} className="pl-0 pr-0">
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: "0",
                overflow: "hidden",
              }}
            >
              <script
                src="https://studio.stornaway.io/embed/v1/player.js"
                defer
              ></script>
              <iframe
                src="https://player.stornaway.io/embed/09b331bf"
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; gyroscope;autoplay;"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              ></iframe>
            </div>
          </Column>
          <Column span={7} className="pt-1 pb-1">
            <h3 className="resource__title">
              Think It Through: A choose-your-own-path experience
            </h3>
            <div className="resource__grade">Grade 9-12</div>
            <div className="resource__description pt-1 pb-1">
              Facing the common consequences of poor time management, students
              will practice healthy decision-making skills to navigate a hectic
              day while faced with peer pressure related to substance misuse to
              pass the big test!
            </div>
            <div className="resource__action">
              <a
                href="https://player.stornaway.io/embed/09b331bf"
                target="_blank"
                //className={className}
                //data-click-name={moduleName}
                data-click-type="Module Launch"
                rel="noopener noreferrer"
              >
                <Icon name="module" inline />
                Get Started
              </a>
            </div>
          </Column>
        </Row>
      </Container>
    </Section> */}

    {/* Ready-To-Use Activities */}
    <Section className="midgray-background">
      <Container>
        <div className="resource__label">Ready-to-use Activities</div>
        <Row className="white-background educators__CYA-row pt-1 pb-1 educators__equal-height">
          <Column span={4}>
            <Resource
              image="CVS-Pharma-RTU-Thumbnail-ImpactsofSocialMedia.jpg"
              title="Impacts of Social Media"
              grade="Grades 9-12"
              resourceContentClass="educators__CYA-resource-content"
              resourceContainerClass="educators__CYA-resource-with-shadow"
              description={
                <p>
                  Learn about being mindful and constructive when spending time
                  online with this wellness activity.
                </p>
              }
              actions={[
                {
                  label: "Open Ready-to-Use Activity",
                  url:
                    "https://app.discoveryeducation.com/learn/player/6abe7d1a-47fe-4523-b131-93e2e8f44cae",
                  type: "external",
                  locked: false,
                },
              ]}
            />
          </Column>
          <Column span={4}>
            <Resource
              image="CVS-Pharma-RTU-Thumbnail-NavigatingSocialInfluence.jpg"
              title="Social Influence"
              grade="Grades 9-12"
              resourceContentClass="educators__CYA-resource-content"
              resourceContainerClass="educators__CYA-resource-with-shadow"
              description={
                <p>
                  Learn about navigating peer pressure through positive
                  interactions with this wellness activity.
                </p>
              }
              actions={[
                {
                  label: "Open Ready-to-Use Activity",
                  url:
                    "https://app.discoveryeducation.com/learn/player/8fab0b86-90cf-49de-9de0-942ab94741e3",
                  type: "external",
                  locked: false,
                },
              ]}
            />
          </Column>
          <Column span={4}>
            <Resource
              image="CVS-Pharma-RTU-Thumbnail-MentalBreathingSpace.jpg"
              title="Stress Management"
              grade="Grades 9-12"
              resourceContentClass="educators__CYA-resource-content"
              resourceContainerClass="educators__CYA-resource-with-shadow"
              description={
                <p>
                  Learn about proactive stress management techniques with this
                  wellness activity.
                </p>
              }
              actions={[
                {
                  label: "Open Ready-to-Use Activity",
                  url:
                    "https://app.discoveryeducation.com/learn/player/9a51e47c-ccf7-4315-8c78-7ee96608ffa1",
                  type: "external",
                  locked: false,
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  RESOURCE HORIZONTAL ***************/}
    <Section className="resource-container midgray-background pt-0">
      <Column span={12}>
        <Container>
          <div>
            <h2>Digital Exploration</h2>
            <p>
              Explore an interactive module designed to empower responsible
              decision-making around substance misuse in the face of peer
              pressure.
            </p>
          </div>
        </Container>
        <Resource
          label="Digital Exploration"
          image="thumbnail-digital-exploration@2x.jpg"
          title="Prescription Medication Misuse and the Adolescent Brain and Body"
          grade="Grades 9-12"
          description={
            <p>
              Learn about the effects of prescription medication misuse on the
              developing adolescent brain and body. This information will equip
              you with the knowledge to make informed and responsible decisions
              when confronted with peer pressure regarding prescription
              medication misuse.
            </p>
          }
          actions={[
            {
              label: "Get Started",
              path: "/prescription-medication-misuse",
              type: "module",
              moduleName: "Prescription Medication Misuse",
              locked: false,
            },
            // {
            //   label: "Educator Guide",
            //   path: "/docs/CVS-Pharma-DLB-Responding_to_Peer_Pressure-Pharmacists.pdf",
            //   type: "download",
            //   size: "PDF 1,000,000 GB",
            //   locked: false
            // }
          ]}
          horizontal
        />
      </Column>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="subfooter">
      <Container>
        <Row>
          <Column span={4}>
            <img
              src={SubFooterImage}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h3>Facts:</h3>
            <p>
              A recent longitudinal study found that approximately one-third of
              students were offered stimulants for nonmedical use in the past
              year. Of those who were offered stimulants, more than one-third
              misused them.
            </p>
            <p>
              -<em>National Library of Medicine (2019)</em>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)
export default StudentsPage
